import React from 'react'
import {Link} from 'gatsby'
import Figure from '../components/figure'
import BlockContent from '../components/block-content'
import { getBlogUrl } from '../utils/helpers'

const BlogPostPreview = (props) => {
  return (
    <article className={props.className}>
      {props.image && (
        <Link to={getBlogUrl(props.category.slug.current, props.slug.current)}>
          <Figure className='mb-5' node={props.image} aspectRatio={0.9358490566} />
        </Link>
      )}
      {props.category.title && (
        <Link to={`/blogg/${props.category.slug.current}`}>
          <div className='mb-3 uppercase tracking-wide label bg-red-light text-red inline-block rounded-sm py-1 px-2'>
            {props.category.title}
          </div>
        </Link>
      )}
      <Link className='no-underline' to={getBlogUrl(props.category.slug.current, props.slug.current)}>
        {props.compact ? (
          <h3 className='mb-5 font-serif font-normal text-primary'>{props.title}</h3>
        ) : (
          <h2 className='mb-5 font-serif font-normal text-primary'>{props.title}</h2>
        )}
      </Link>
      <BlockContent className={`mb-5 ${props.compact ? 'text-sm' : ''}`} blocks={props.lead} />
      <Link className={`read-more mr-6 ${props.compact ? 'text-sm' : ''}`} to={getBlogUrl(props.category.slug.current, props.slug.current)}>
        Les mer
      </Link>
      {props.estimatedReadingTime && (
        <small>Lesetid: {props.estimatedReadingTime} minutter</small>
      )}
    </article>
  )
}

export default BlogPostPreview